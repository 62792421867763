import "./interview.scss";
import React, { useEffect } from "react";
import { List, message, Typography } from "antd";
import { getResponseByUUID } from "../../api/responses";
import { handleConcat } from "../../utilities";
import { useMobileDetection } from "../../helpers/hooks/useMobileDetector";

const InterViewSharePage = () => {
  //get uuid from url
  const [uuid, setUuid] = React.useState("");
  const [currentQuestion, setCurrentQuestion] = React.useState(0);
  const [question, setQuestion] = React.useState(null);
  const [questions, setQuestions] = React.useState([]);
  const [candidate, setCandidate] = React.useState(null);
  const [status, setStatus] = React.useState("idle");
  const [statusMessage, setStatusMessage] = React.useState("");

  const isMobile = useMobileDetection()
  useEffect(() => {
    if (!window) return;
    const url = window.location.href;
    const uuid = url.split("/")[4];
    setUuid(uuid);
  }, [window]);

  useEffect(() => {
    if (uuid) {
      //call api to get questions
      setStatus("loading");
      getResponseByUUID(uuid)
        .then((result) => {
          const candidate = result.data?.candidate || {};
          const response = result.data?.response || [];
          setQuestions(response);
          setCandidate(candidate);
          setQuestion(response[0]);
          setStatus("success");
          setStatusMessage("");
        })
        .catch((err) => {
          setStatus("error");
          setStatusMessage(err.message);
          message.error("Something went wrong");
        });
    }
  }, [uuid]);

  if (status === "loading") {
    return (
      <div className="p-4 -mt-12 bg-white rounded-md min-h-[500px] flex items-center justify-center">
        <Typography.Title level={3} className="text-center ">
          Loading...
        </Typography.Title>
      </div>
    );
  }
  if (status === "error") {
    return (
      <div className="p-4 -mt-12 bg-white rounded-md min-h-[500px] flex items-center justify-center">
        <Typography.Title level={3} className="text-center ">
          {statusMessage || "Something went wrong"}
        </Typography.Title>
      </div>
    );
  } else {
    return (
      <div className="p-4 -mt-4 bg-white rounded-md">
        <div className="text-start">
          <Typography.Title level={6} className="text-start ml-4">
            {(candidate?.first_name || "") + " " + (candidate?.last_name || "")}
          </Typography.Title>
          <Typography.Title level={4} className="text-start ml-4">
            {candidate?.created
              ? new Date(candidate?.created)
                  .toUTCString()
                  .split(" ")
                  .slice(0, 4)
                  .join(" ")
              : ""}
          </Typography.Title>
          {/* <Typography.Title level={4} className="text-start ml-4">
            {candidate?.phone_number}
          </Typography.Title>
          <Typography.Title level={4} className="text-start ml-4">
            {candidate?.email}
          </Typography.Title> */}
        </div>
        <hr />
        <div className="mt-4"></div>
        <div
          className="flex flex-col md:flex-row "
          // style={{ minHeight: "700px" }}
        >
          <div className="w-full md:w-1/2 lg:w-7/12 text-base p-4 space-y-4 self-start">
            {question && (
              <>
                <div className="text-gray-500 font-semibold flex items-center">
                  <i className="fa-solid fa-message mr-2" />
                  <span> Question {currentQuestion + 1}</span>
                </div>
                <div
                  className="custom-markdown text-black text-lg font-semibold"
                  dangerouslySetInnerHTML={{
                    __html: question.title,
                  }}
                />
                {question.text_response ? (
                  <div
                    className="aspect-video p-4 rounded-lg border-2 border-algaeGreen-500 overflow-y-auto"
                    id="preview"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question.text_response,
                      }}
                    />
                  </div>
                ) : (
                  <video
                    src={isMobile ? question.answer + "#t=0.001" : question.answer}
                    preload="metadata"
                    className="aspect-video w-full bg-black rounded-xl object-cover"
                    controls
                  />
                )}
                <div className="mt-4 text-center w-full mb-6 text-sm text-gray-500">
                  {question.answer ? "Retakes" : "Edits"} Used : {question.take}
                </div>
              </>
            )}
          </div>
          <div className="w-full md:w-1/2 lg:w-5/12 overflow-y-auto self-stretch flex flex-col justify-between space-y-4 p-2 previewLayout">
            <div
              className="rounded-lg grow overflow-y-auto border-2 "
              style={{
                boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.5)",
              }}
            >
              <div className="text-gray-500 text-base font-semibold sticky pt-4 -mt-1 px-4 top-0 z-50 bg-white">
                Answer {currentQuestion + 1} of {questions.length}
              </div>
              <List
                className="px-2"
                itemLayout="horizontal"
                dataSource={questions}
                renderItem={(question, index) => (
                  <List.Item>
                    <div
                      onClick={() => {
                        setCurrentQuestion(index);
                        setQuestion(questions[index]);
                      }}
                      className={`h-full w-full flex items-start rounded-lg p-2 space-x-2 ${
                        currentQuestion === index
                          ? "bg-gray-200"
                          : " hover:bg-gray-100 cursor-pointer"
                      }`}
                    >
                      <div className="flex flex-col self-stretch space-y-2 ">
                        <div className="flex items-center">
                          <i className="fa-solid fa-message mr-2" />
                          <span className="text-base">
                            Question {index + 1}{" "}
                          </span>
                        </div>
                        <div
                          className="custom-markdown text-black text-sm font-semibold"
                          dangerouslySetInnerHTML={{
                            __html: handleConcat(question.title, 170),
                          }}
                        />
                        <div className="grow text-base flex space-x-2">
                          <div className="w-2/4 flex flex-col">
                            {question.text_response ? (
                              <></>
                            ) : (
                              <VideoPreviewTile
                                url={question.answer}
                                className="flex-grow"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const VideoPreviewTile = ({ url }) => {
  // const [duration, setDuration] = React.useState(0);
  return (
    <div className="bg-black rounded-md aspect-video relative">
      <video
        className="w-full bg-black rounded-md aspect-video object-cover"
        src={url}
        //load video before playing
        onLoadedMetadata={(e) => {
          // setDuration(e.target.duration.toFixed(2));
        }}
      />
      {/* <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
        {duration > 0 && <div className="text-white text-lg font-semibold">{duration}</div>}
      </div> */}
    </div>
  );
};

export default InterViewSharePage;
