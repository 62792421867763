import React, { useEffect, useState } from "react";
import { message, Popover } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../tacnique-logo.svg";
import { useSelector } from "react-redux";
import { logout } from "../../api/authorization";
import { MenuDropdownLayout } from "./MenuDropdownLayout";
import { classNames } from "../../utilities";
import { useMobileDetection, useSmallScreenDetection } from "../../helpers/hooks/useMobileDetector";

const navlinks = [
  {
    name: "Dashboard",
    href: process.env.REACT_APP_REDIRECT_URL + "/company/dashboard",
  },
  {
    name: "Requirements",
    href: process.env.REACT_APP_REDIRECT_URL + "/company/requirements",
  },

  {
    name: "Candidates",
    href: process.env.REACT_APP_REDIRECT_URL + "/company/candidates",
  },
  {
    name: "Interviews",
    href: "/interview",
    children: [
      {
        name: "Question Bank",
        href:
          process.env.REACT_APP_REDIRECT_URL + "/company/interviews/questions",
      },
      {
        name: "Interview Roles",
        href: process.env.REACT_APP_REDIRECT_URL + "/company/interviews/roles",
      },
      {
        name: "Interview Calendar",
        href:
          process.env.REACT_APP_REDIRECT_URL + "/company/interviews/calendar",
      },
      {
        name: "Async Video Interviews",
        href: "/interview",
      },
    ],
  },
];

const HeaderComponent = () => {
  const interview = useSelector((state) => state.interview);
  const isSmallScreen = useSmallScreenDetection()
  let location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("api_key")) {
      setIsLoggedIn(true);
    } else if (
      location.pathname.includes("/submit/") ||
      location.pathname.includes("/invite") ||
      location.pathname.includes("/share") ||
      location.pathname.includes("/tips") ||
      location.pathname.includes("/test") ||
      location.pathname.includes("/getting-started")
    ) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
      if (location.pathname !== "/authorization") {
        navigate("/authorization");
      }
    }
  }, [location.pathname]);

  const handleLogout = () => {
    //logout api
    logout()
      .then((data) => {
        if (data.status === 200) {
          localStorage.removeItem("api_key");
          localStorage.removeItem("email");
          //redirect to https://tacnique.com/login
          window.location.href = process.env.REACT_APP_REDIRECT_URL + "/login?redirect=async-meet";
        }
      })
      .catch(() => {
        message.error({
          key: "auth",
          content: "Something went wrong",
        });
      });
  };

  return (
    <>
      <div className="relative header_container top-0 shadow">
        {/* sticky */}
        <div className="container h-16 md:px-8 max-w-7xl mx-auto px-2 sm:px-4 lg:px-8 w-full flex justify-between items-center ">
          <span className="flex grow space-x-2 items-center">
            <div className="text-2xl font-bold text_primary shrink-0 pr-8">
              <a
                href={process.env.REACT_APP_REDIRECT_URL + "/company/dashboard"}
                className="text_primary h-8"
              >
                <img src={logo} className="App-logo h-8" alt="logo" />
              </a>
            </div>
            {!(
              location.pathname.includes("/submit/") ||
              location.pathname.includes("/invite") ||
              location.pathname.includes("/share") ||
              location.pathname.includes("/getting-started") ||
              location.pathname.includes("/test")
            ) && (
              <ul className="flex flex-row gap-x-8 md:flex m-0">
                {navlinks?.map((link, i) => {
                  return link.name === "Interviews" ? (
                    <MenuDropdownLayout
                      item={link}
                      renderTrigger={() => (
                        <span
                          key={link.name}
                          className={classNames(
                            "pb-5 pt-6 font-medium text-sm relative",
                            link.name === "Interviews"
                              ? "text-primary-600"
                              : "text-gray-500"
                          )}
                          data-test-id={`nav-${link.name
                            .replace(" ", "-")
                            .toLowerCase()}`}
                        >
                          <li
                            key={link.name}
                            className="py-3 h-16 flex items-center font-medium text-sm text-primary-500 relative"
                          >
                            {link.name}
                            <span className="w-[95px] absolute bottom-0 left-[-13px] right-0 border-b-4 border-primary-500" />
                          </li>
                        </span>
                      )}
                      key={i}
                    />
                  ) : (
                    <a style={{ paddingTop: "23px" }} href={link.href} key={i}>
                      <li
                        key={link.name}
                        className="py-3 h-16 flex items-center font-medium text-sm text-gray-500"
                      >
                        {link.name}
                      </li>
                    </a>
                  );
                })}
              </ul>
            )}
          </span>
          {isLoggedIn &&
            !(
              location.pathname.includes("/submit/") ||
              location.pathname.includes("/invite") ||
              location.pathname.includes("/share") ||
              location.pathname.includes("/getting-started") ||
              location.pathname.includes("/test")
            ) && (
              <div className="user_container">
                <Popover
                  placement="bottomRight"
                  className="user_container"
                  content={
                    <UserMenu
                      user={{ email: localStorage.getItem("email") }}
                      handleLogout={handleLogout}
                    />
                  }
                  trigger="click"
                >
                  <button
                    className="hidden md:inline-block bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-200"
                    type="button"
                  >
                    <div
                      className="flex-shrink-0 rounded-full group-hover:opacity-75 font-bold flex items-center justify-center text-white text-lg  bg-pink-500"
                      style={{
                        height: "3rem",
                        width: "3rem",
                      }}
                    >
                      {localStorage.getItem("email")?.charAt(0)?.toUpperCase()}
                    </div>
                  </button>
                  <button
                    className="inline-flex md:hidden items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
                    type="button"
                  >
                    <span className="sr-only">Open main menu</span>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="bars"
                      className="svg-inline--fa fa-bars h-5 w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"
                      ></path>
                    </svg>
                  </button>
                </Popover>
              </div>
            )}
        </div>
        {interview.isDemo && (
          <div className={`absolute top-[100%] left-0 right-0 px-2 py-1 text-center bg-primary-700 text-white ${isSmallScreen ? 'text-sm leading-tight' : 'text-base leading-normal'}`}>
            <p className="m-0">
              <span className="h-5 w-5 mr-2 text-xs font-bold rounded-full border-2 border-white inline-block">
                i
              </span>
              You are in practice mode. Please relax and get comfortable with
              the process, no data will be stored.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const UserMenu = ({ user, handleLogout }) => (
  <ul className="-mx-4 -my-2 min-w-[250px]">
    <a
      data-test-id="account-page"
      href={`${process.env.REACT_APP_REDIRECT_URL}/company/account`}
      role="none"
    >
      <div
        className="block px-4 py-2  text-sm text-gray-700 flex hover:bg-gray-100"
        role="none"
      >
        <div
          className="print:hidden flex-shrink-0 rounded-full group-hover:opacity-75 font-bold flex items-center justify-center text-white text-lg  bg-pink-500"
          role="none"
          style={{ height: "3rem", width: "3rem" }}
        >
          {user.email.charAt(0).toUpperCase()}
        </div>
        <div className="px-4 flex flex-col justify-center" role="none">
          {/*<div className="text-sm font-bold flex" role="none">{user?.name || 'Temp'}</div>*/}
          <div className="flex" role="none">
            <div className="text-sm text-gray-500" role="none">
              {user?.email}
            </div>
          </div>
        </div>
      </div>
    </a>
    {navlinks.map((link, index) => {
      return link.name === "Interviews" ? (
        <Link
          className="block md:hidden rounded-md px-4 py-2 text-base text-primary-500 font-medium hover:bg-gray-100 hover:text-gray-800"
          to={link.path}
          key={index}
        >
          {link.name}
        </Link>
      ) : (
        <a
          className="block md:hidden rounded-md px-4 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
          data-test-id="mobile-profile-page"
          href={link.href}
        >
          {link.name}
        </a>
      );
    })}
    <span
      className="block px-4 py-2  text-base text-gray-500 font-medium hover:bg-gray-100 cursor-pointer"
      onClick={() => handleLogout()}
    >
      Logout
    </span>
  </ul>
);

export default HeaderComponent;