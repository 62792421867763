import React, { useEffect, useState } from "react";
import { Col, Form, Input, message, Row, Tooltip, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { getResponse } from "../../api/responses";
import { ShareAltOutlined } from "@ant-design/icons";
import { getInterview } from "../../api/interviews";

const InterViewPreviewPage = () => {
  let navigate = useNavigate();
  let { responseUuid, uuid } = useParams();
  const [interviewInfo, setInterviewInfo] = useState({
    title: "",
    candidate: false,
    response: [],
  });

  const [interviewTitle, setInterviewTitle] = useState("");
  const [shareURL, setShareURL] = useState(null);
  const [form] = Form.useForm();
  useEffect(() => {
    getInterview(uuid)
      .then(({ data }) => {
        setInterviewTitle(data.title);
      })
      .catch(() => {});
    getResponse(responseUuid, uuid)
      .then(({ data }) => {
        setInterviewInfo(data);
        setShareURL(
          `${process.env.REACT_APP_FRONTEND_URL}/async-interview/${data.uuid}/share`
        );
        form.setFieldsValue(data.candidate);
      })
      .catch(() => {});
  }, []);

  const handleShareResponse = () => {
    navigator.clipboard.writeText(shareURL);
    message.success("Link copied to clipboard");
  };

  return (
    <div>
      <div className="max-w-3xl mx-auto py-4 lg:max-w-7xl inline-flex justify-between w-full">
        <button
          className="inline-flex items-center px-4 py-2 rounded-md border border-gray-400 bg-white text-sm font-medium text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-gray-400 focus:border-gray-400 shadow-sm"
          onClick={() => {
            navigate(`/async-interview/${uuid}`);
          }}
        >
          Back
        </button>
      </div>
      <div className="my-2 p-4 bg-white rounded">
        <div className="text-xl mb-8">Candidate Information</div>
        {interviewInfo && (
          <>
            <div className="">
              <Form
                form={form}
                labelCol={{ flex: "110px" }}
                labelAlign="left"
                labelWrap
                wrapperCol={{ flex: 1 }}
                disabled={true}
                onChange={() => {
                  form.setFieldsValue(interviewInfo["candidate"]);
                }}
              >
                <Row className="w-full">
                  <Col span={24}>
                    <Form.Item
                      name="first_name"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500 ">
                          {" "}
                          First Name
                        </div>
                      }
                    >
                      <Input className="c_input-2" />
                    </Form.Item>
                    <hr className="my-2 mb-4" />

                    <Form.Item
                      name="last_name"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500">
                          {" "}
                          Last Name
                        </div>
                      }
                    >
                      <Input className="c_input-2 " />
                    </Form.Item>
                    <hr className="my-2 mb-4" />

                    <Form.Item
                      name="email"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500">
                          Email
                        </div>
                      }
                    >
                      <Input className="c_input-2 " required />
                    </Form.Item>
                    <hr className="my-2 mb-4" />
                    <Form.Item
                      name="phone_number"
                      label={
                        <div className="my-4 text-base font-bold text-gray-500">
                          Contact No
                        </div>
                      }
                    >
                      <Input className="c_input-2" type="number" />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        )}
      </div>
      <hr />
      <div className="flex mt-4 items-center">
        <Typography.Title>{interviewTitle}</Typography.Title>
        {shareURL && (
          <div className="text-center ml-8 mb-4">
            <Tooltip title="Share Response" color="#15808D">
              <button className="text-3xl" onClick={handleShareResponse}>
                <ShareAltOutlined style={{ color: "#15808D" }} />
              </button>
            </Tooltip>
          </div>
        )}
      </div>

      {interviewInfo.response && (
        <ul>
          {interviewInfo.response.map((que, index) => {
            return (
              <li key={index}>
                <QuestionTile que={que} index={index} />
                <hr />{" "}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

const QuestionTile = ({ que, index }) => {
  return (
    <div className="flex w-100 bg-white p-4 pt-6 pr-8 rounded-md my-2 relative">
      <div className="grow">
        <p className="text-gray-600 text-base font-semibold flex  items-center">
          <i className="fa-solid fa-message mr-2" />
          <span className="ml-1">Question {index + 1} </span>
        </p>
        <Row className="mt-4">
          <Col span={12}>
            <div
              className="custom-markdown text-black text-lg font-semibold"
              dangerouslySetInnerHTML={{
                __html: que.title,
              }}
            />
            <div className="my-4 text-base font-bold text-gray-500">
              Retakes used : {que.take}
            </div>
          </Col>
          <Col span={12}>
            {!que.answer ? (
              <div className="border-2 border-algaeGreen-500 rounded-md aspect-video p-4">
                <div
                  id="preview"
                  dangerouslySetInnerHTML={{ __html: que.text_response }}
                />
              </div>
            ) : (
              <video
                controls
                preload="metadata"
                className="aspect-video object-cover w-full bg-black rounded-md"
              >
                <source src={que.answer} />
              </video>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default InterViewPreviewPage;
